<template>
  <VContainer
    v-if="!loading && !isMobile"
    class="pa-0"
  >
    <VRow>
      <VCol>
        <h1
          data-test="levels-view-h1"
          class="tt-text-headline-1"
        >
          {{ $t('menu.handbook') }}
        </h1>
      </VCol>
    </VRow>
    <VRow
      v-if="events.length"
      class="mb-3"
    >
      <Carousel
        key="test"
        :title="$t('names.events')"
        data-test-prefix="events-carousel"
      >
        <template
          v-for="(event,i) in events"
        >
          <EventCard
            :key="i"
            :to="{name : Names.R_APP_EVENTS, params : {eventId : event.task.id}}"
            shadow="always"
            class="pa-5"
            :task="event.task"
            :expires="event.expires"
            :card-type="event.task.type"
          />
        </template>
      </Carousel>
    </VRow>
    <VRow v-if="contacts.length">
      <Carousel
        :title="$t('names.contacts')"
        data-test-prefix="contacts-carousel"
      >
        <ContactCard
          v-for="(contact,i) in contacts"
          :key="i"
          :task="contact.task"
          :contact="contact.meta"
          :show-link-to-task="enableHandbookMT"
        />
      </Carousel>
    </VRow>
    <VRow v-if="files.length">
      <Carousel
        :title="$tc('names.files',1)"
        data-test-prefix="files-carousel"
      >
        <FileCard
          v-for="(file,i) in files"
          :key="i"
          :file-type="file.meta.type.toLowerCase()"
          :meta="file.meta"
          :task="file.task"
        />
      </Carousel>
    </VRow>
    <VRow v-if="videos.length">
      <Carousel
        :title="$t('names.videos')"
        data-test-prefix="videos-carousel"
      >
        <!--TODO отрефакторить карточку или сделать другую, добавить загрузку-->
        <VideoItem
          v-for="(video,i) in videos"
          :key="i"
          :task="video.task"
          :meta="video.meta"
          class="video-card"
          :show-description="enableHandbookMT"
          :show-link-to-task="enableHandbookMT"
        />
      </Carousel>
    </VRow>
  </VContainer>
  <div v-else-if="!loading">
    <AppBarMobile>
      <VRow justify="center">
        <VCol cols="auto">
          <span
            data-test="levels-view-h1"
            class="tt-text-title-1 white--text"
          >
            {{ $t('menu.handbook') }}
          </span>
        </VCol>
      </VRow>
      <template #extension>
        <TabsWrapper
          v-model="selectedTab"
          height="26"
          :tabs="tabs"
          dark
          centered
          mandatory
          color="white"
          underline="none"
          data-test="tabs"
        />
      </template>
    </AppBarMobile>
    <VContainer
      v-if="!loading"
    >
      <VRow>
        <VCol>
          <VTabsItems
            v-model="selectedTab"
            class="overflow-visible"
          >
            <VTabItem
              v-for="tab in tabs"
              :key="tab.text"
              :data-test="`tab-content-` + tab.value"
              :transition="false"
              :value="tab.value"
            >
              <VContainer>
                <template v-if="tab.value==='events'">
                  <VRow>
                    <VCol
                      v-for="(event,i) in events"
                      :key="i"
                      cols="12"
                    >
                      <EventCard
                        :to="{name : Names.R_APP_EVENTS, params : {eventId : event.task.id}}"
                        shadow="always"
                        class="pa-5"
                        :task="event.task"
                        :expires="event.expires"
                        :card-type="event.task.type"
                      />
                    </VCol>
                  </VRow>
                </template>
                <template v-if="tab.value==='contacts'">
                  <VRow>
                    <VCol
                      v-for="(contact,i) in contacts"
                      :key="i"
                      cols="12"
                    >
                      <ContactCard
                        :contact="contact.meta"
                      />
                    </VCol>
                  </VRow>
                </template>
                <template v-if="tab.value==='files'">
                  <VRow>
                    <VCol
                      v-for="(file,i) in files"
                      :key="i"
                      cols="12"
                    >
                      <FileCard
                        :file-type="file.meta.type.toLowerCase()"
                        :meta="file.meta"
                        :task="file.task"
                      />
                    </VCol>
                  </VRow>
                </template>
                <template v-if="tab.value==='videos'">
                  <VRow>
                    <VCol
                      v-for="(video,i) in videos"
                      :key="i"
                      cols="12"
                    >
                      <VideoItem

                        :meta="video.meta"
                        class="video-card"
                        :show-description="false"
                      />
                    </VCol>
                  </VRow>
                </template>
              </VContainer>
            </VTabItem>
          </VTabsItems>
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script>

import Carousel from '@/components/carousel/Carousel.vue';
import FileCard from '@/components/cards/FileCard.vue';
import VideoItem from '@/components/items/VideoItem.vue';
import EventCard from '@/components/cards/EventCard.vue';
import { mapActions, mapState } from 'vuex';
import { GET_EVENTS_ACTION, GET_HANDBOOK_ACTION } from '@/plugins/vuex/actionTypes';
import ContactCard from '@/components/cards/ContactCard.vue';
import TabsWrapper from '@/components/shared/TabsWrapper.vue';
import AppBarMobile from '@/components/app/AppBarMobile.vue';

export default {
  name: 'HandbookView',
  components: {
    AppBarMobile,
    TabsWrapper,
    ContactCard,
    EventCard,
    VideoItem,
    FileCard,
    Carousel,
  },
  inject: ['Names', 'glFeatures'],
  data() {
    return {
      selectedTab: '',
      loading: false,
    };
  },
  computed: {
    ...mapState('handbook', ['events', 'files', 'contacts', 'videos']),
    enableHandbookMT() {
      return this.glFeatures('handbook_mt');
    },
    tabs() {
      return [
        {
          text: this.$t('menu.events'),
          value: 'events',
        }, {
          text: this.$tc('names.files', 1),
          value: 'files',
        },
        {
          text: this.$t('names.contacts'),
          value: 'contacts',
        },
        {
          text: this.$t('names.videos'),
          value: 'videos',
        }];
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    ...mapActions('handbook', { getHandbookAction: GET_HANDBOOK_ACTION, getEventsAction: GET_EVENTS_ACTION }),
    async init() {
      try {
        this.loading = true;
        await Promise.all([this.getHandbookAction(), this.getEventsAction({ data: { include_inactive: true } })]);
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.video-card{
  min-height: 200px;
}
</style>
