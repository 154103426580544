<script>
export default {
  name: 'Renderer',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  render(createElement) {
    const renderItem = this.$slots.default ? this.$slots.default : this.item;
    return createElement('div', { staticClass: 'fill-height' }, [renderItem]);
  },
};
</script>
