<template>
  <VContainer v-if="preparedSlots.length">
    <VRow
      justify="space-between"
      dense
    >
      <VCol cols="auto">
        <slot
          name="title"
        >
          <div
            class="tt-text-title-2"
            :data-test="`${dataTestPrefix}-title`"
          >
            {{ title }}
          </div>
        </slot>
      </VCol>
      <VCol
        v-if="showControls"
        cols="auto"
      >
        <TTBtn
          x-small
          rounded
          :disabled="isFirstPage"
          class="mr-3"
          color="tt-secondary-danger"
          :data-test="`${dataTestPrefix}-button-prev`"
          @click="prev"
        >
          <VIcon>
            fal fa-angle-left
          </VIcon>
        </TTBtn>
        <TTBtn
          x-small
          rounded
          :disabled="isLastPage"
          color="tt-secondary-danger"
          :data-test="`${dataTestPrefix}-button-next`"
          @click="next"
        >
          <VIcon>
            fal fa-angle-right
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>
    <VRow>
      <VCol class="py-0">
        <VWindow
          v-model="currentPage"
          class="carousel"
        >
          <VWindowItem
            v-for="(item,itemIndex) in preparedSlots"
            :key="`window-${itemIndex}`"
            class="py-4"
          >
            <VRow>
              <VCol
                v-for="(card,cardIndex) in item"
                :key="`card-${cardIndex}`"
                cols="4"
              >
                <Renderer :item="card" />
              </VCol>
            </VRow>
          </VWindowItem>
        </VWindow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>

import Renderer from '@/components/cards/Renderer.vue';

export default {
  name: 'Carousel',
  components: { Renderer },
  props: {
    title: {
      type: String,
      default: '',
    },
    dataTestPrefix: {
      type: String,
      default: 'carousel',
    },
  },
  data() {
    return {
      currentPage: 0,
    };
  },
  computed: {
    showControls() {
      return this.preparedSlots.length > 1;
    },
    preparedSlots() {
      if (!this.$slots.default) {
        return [];
      }
      return this.groupArrayBy(this.$slots.default, 3);
    },
    isFirstPage() {
      return this.currentPage === 0;
    },
    isLastPage() {
      return this.currentPage + 1 === this.preparedSlots.length;
    },
  },
  methods: {
    groupArrayBy(array, count) {
      return array.reduce((r, e, i) => (i % count ? r[r.length - 1].push(e) : r.push([e])) && r, []);
    },
    next() {
      if (this.currentPage + 1 < this.preparedSlots.length) {
        this.currentPage += 1;
      }
    },
    prev() {
      if (this.currentPage + 1 > 0) {
        this.currentPage -= 1;
      }
    },
  },
};
</script>

<style lang="scss">
.carousel {
  overflow: visible;

  &.v-window {
    overflow: visible;
  }

  .v-window__container {
    padding: 0 32px;
    margin: 0 -32px;
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      height: 100%;
      top: 0;
      right: 0;
      width: 20px;
      box-shadow: inset -20px 0 20px 0 #fff;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      height: 100%;
      top: 0;
      left: 0;
      width: 20px;
      box-shadow: inset 20px 0 20px 0 #fff;
    }
  }
}

</style>
