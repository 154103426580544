<template>
  <CardWrapper
    data-test="contact-card"
    class="fill-height pa-5 contact-card"
  >
    <ContactsItem
      :meta="contact"
      :task="task"
      :show-link-to-task="showLinkToTask"
    />
  </CardWrapper>
</template>

<script>
import CardWrapper from '@/components/cards/CardWrapper.vue';
import ContactsItem from '@/components/items/ContactsItem.vue';

export default {
  name: 'ContactCard',
  components: { ContactsItem, CardWrapper },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      default: () => ({}),
    },
    showLinkToTask: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.contact-card{
  min-height: 148px;
}
</style>
