<template>
  <CardWrapper
    v-bind="$attrs"
    :height="$attrs.height || 136"
    data-test="event-card"
  >
    <VRow>
      <VCol cols="auto">
        <VAvatar
          :color="icon.background"
          class="rounded-lg"
          size="40"
          data-test="event-card-avatar"
        >
          <VIcon :class="icon.text">
            {{ icon.icon }}
          </VIcon>
        </VAvatar>
      </VCol>
      <VCol>
        <VRow dense>
          <VCol cols="auto">
            <VBadge
              :value="!isShown"
              inline
              data-test="event-card-badge"
              dot
              color="tt-light-red"
            >
              <slot name="title">
                <div
                  data-test="event-card-title"
                  style="hyphens:auto"
                  lang="ru"
                  class="tt-text-body-2"
                >
                  {{ title }}
                </div>
              </slot>
            </VBadge>
          </VCol>
        </VRow>
        <VRow no-gutters>
          <VCol cols="12">
            <slot name="description">
              <div
                class="tt-light-mono-46--text tt-text-body-2"
                data-test="event-card-description"
              >
                <span class="text-lowercase">{{ $t('time.until') }}</span> {{ expiresDate }}
              </div>
            </slot>
          </VCol>
        </VRow>
        <VRow dense>
          <VCol
            v-if="task.isCompleted"
            cols="auto"
          >
            <ChipStatus
              :status="$options.COMPLETED"
              :item-type="$options.chipStatusTypes.EVENT"
              show-icon
            />
          </VCol>
          <VCol
            v-if="showDateChip"
            cols="auto"
          >
            <ChipDate
              :date="task.description"
            />
          </VCol>
          <VCol
            v-if="task.durationMin && !task.isCompleted"
            cols="auto"
          >
            <ChipDuration
              :duration="task.durationMin"
            />
          </VCol>
          <VCol
            v-if="task.maxBonus"
            cols="auto"
          >
            <ChipBonus
              :max="task.maxBonus"
              :current="task.bonus"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </CardWrapper>
</template>

<script>

import CardWrapper from '@/components/cards/CardWrapper.vue';
import { taskType, COMPLETED, chipStatusTypes } from '@/helpers/constants';
import ChipDuration from '@/components/chips/ChipDuration.vue';
import ChipBonus from '@/components/chips/ChipBonus.vue';
import ChipStatus from '@/components/chips/ChipStatus.vue';
import { cutString } from '@/helpers/stringHelpers';
import ChipDate from '@/components/chips/ChipDate.vue';

export default {
  name: 'EventCard',
  components: {
    ChipDate,
    ChipStatus,
    ChipBonus,
    ChipDuration,
    CardWrapper,
  },
  COMPLETED,
  taskType,
  chipStatusTypes,
  inject: ['Names'],
  props: {
    expires: {
      type: String,
      default: '',
    },
    task: {
      type: Object,
      default: () => ({}),
    },
    titleLength: {
      type: Number,
      default: 40,
    },
    cardType: {
      type: String,
      validator(val) {
        return [taskType.BUG, taskType.POOL, taskType.CHECKPOINT].includes(val);
      },
      default: taskType.BUG,
    },
  },
  computed: {
    isShown() {
      return this.task.content.every((taskContent) => !!taskContent.firstShown);
    },
    title() {
      return cutString(this.task?.name, this.titleLength);
    },
    expiresDate() {
      if (!this.expires) {
        return null;
      }
      return `${this.$dayjs(this.expires).format('D MMMM YYYY')}`;
    },
    icon() {
      switch (this.cardType) {
        case taskType.POOL: return {
          background: 'tt-light-yellow pale',
          text: 'tt-light-yellow--text base',
          icon: 'fal fa-clipboard-list',
        };
        case taskType.BUG: return {
          background: 'tt-light-blue pale',
          text: 'tt-light-blue--text',
          icon: 'fal fa-question-circle',
        };
        case taskType.CHECKPOINT: return {
          background: 'tt-light-green pale',
          text: 'tt-light-green--text',
          icon: 'fal fa-calendar-star',
        };
        default: return {
          background: 'tt-light-yellow pale',
          text: 'tt-light-yellow--text base',
          icon: 'fal fa-clipboard-list',
        };
      }
    },
    showDateChip() {
      return (this.task.description && this.cardType === taskType.CHECKPOINT) && !this.task.isCompleted;
    },
  },
};
</script>
