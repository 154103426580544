<template>
  <CardWrapper
    flat
    class="fill-height pa-5 file-card"
    corner-cut
    data-test="file-card"
    shadow="never"
  >
    <VRow
      class="fill-height"
      no-gutters
    >
      <VCol>
        <a
          :href="meta.file"
          :download="meta.filename"
          class="file d-flex pa-2 ma-n2"
          data-test="document-item-link"
        >
          <VRow dense>
            <VCol
              cols="auto"
            >
              <VAvatar
                :color="icon.background"
                class="rounded-lg"
                size="40"
              >
                <VIcon :class="icon.text">
                  {{ icon.icon }}
                </VIcon>
              </VAvatar>
            </VCol>
            <VCol class="pl-2">
              <div
                data-test="document-item-link-filename"
                class="tt-text-body-1 tt-light-mono-100--text"
              >
                {{ title }}.{{ fileType }}
              </div>
              <div
                data-test="document-item-link-size"
                class="tt-text-body-2 tt-light-mono-46--text"
              >
                {{ meta.size }}
              </div>
            </VCol>
          </VRow>
        </a>
      </VCol>
      <VCol
        cols="12"
        class="pl-13 pt-2"
        align-self="end"
      >
        <div>
          <RouterLink
            :to="{name : Names.R_APP_LEVEL_VIEW, params : {levelId : task.levelId,taskId : task.id}}"
            class="text-decoration-none"
            data-test="file-card-link-redirect"
          >
            {{ $t('button_text.goToTask') }}
          </RouterLink>
        </div>
      </VCol>
    </VRow>
  </CardWrapper>
</template>

<script>
import CardWrapper from '@/components/cards/CardWrapper.vue';
import { cutString } from '@/helpers/stringHelpers';

export default {
  name: 'FileCard',
  components: { CardWrapper },
  inject: ['Names'],
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
    fileType: {
      type: String,
      validator: (v) => ['pdf', 'docx'].includes(v),
      default: 'docx',
    },
  },
  computed: {
    icon() {
      switch (this.fileType) {
        case 'pdf':
          return {
            background: 'tt-light-red pale',
            text: 'tt-light-red--text base',
            icon: 'fal fa-file-pdf',
          };
        case 'docx':
        case 'doc':
        default:
          return {
            background: 'tt-light-blue pale',
            text: 'tt-light-blue--text',
            icon: 'fal fa-arrow-to-bottom',
          };
      }
    },
    title() {
      return cutString(this.meta?.filename, 30);
    },
  },
};
</script>

<style lang="scss" scoped>
.file {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 8px;
&-card{
  min-height: 138px;
}
  &:hover {
    background: rgba(#ccc, 0.1);
  }

  &-icon {
    border-radius: 8px;
    width: 40px;
    height: 40px;
  }
}

.icon {
  width: 24px;
  height: 24px;
}
</style>
